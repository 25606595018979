import { Auth, API } from "aws-amplify"

export default {
  findByCode: async (code) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
    }
    const deliveryLocker = await API.get(
      "smariDeliveryboxAPI",
      `/store-lockers/${code}`,
      init
    ).catch((error) => {
      if (error.response.status === 400) {
        throw error
      }
      if (error.response.status === 404) {
        throw "対象の宅配ボックスが存在しません"
      }
      throw "現在ご利用いただけません。しばらくたってから再度アクセスしてください。"
    })
    return deliveryLocker
  },
}
