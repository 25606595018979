<template>
  <div class="circle-wrapper">
    <div class="circle" :class="setColor('circle', payment_status)">
      <div class="action-name" :class="setColor('name', payment_status)">
        発払
      </div>
    </div>
    <div class="line"></div>
    <div class="circle" :class="setColor('circle', select_status)">
      <div class="action-name" :class="setColor('name', select_status)">
        商品投函
      </div>
    </div>
    <div class="line"></div>
    <div class="circle" :class="setColor('circle', post_status)">
      <div class="action-name" :class="setColor('name', post_status)">
        ボックス<br />
        ドアロック
      </div>
    </div>
    <div class="line"></div>
    <div class="circle" :class="setColor('circle', complete_status)">
      <div class="action-name" :class="setColor('name', complete_status)">
        完了
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentActionFlow",
  props: {
    payment_status: String,
    select_status: String,
    post_status: String,
    complete_status: String,
  },
  methods: {
    setColor(type, status) {
      if (status === "inactive") {
        return `is-${type}-inactive`
      }
      if (status === "done") {
        return `is-${type}-done`
      }
      return `is-${type}-active`
    },
  },
}
</script>

<style scoped>
.circle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px 0;
  margin-bottom: 50px;
}
.circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  position: relative;
}
.is-circle-active {
  border: solid 3px #092c0b;
}
.is-circle-inactive {
  border: solid 3px #808080;
  background-color: #808080;
}
.is-circle-done {
  border: solid 3px #092c0b;
  background-color: #092c0b;
}
.action-name {
  position: absolute;
  width: 100px;
  top: 35px;
  left: 50%;
  font-size: 12px;
  line-height: 14px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}
.is-name-inactive {
  color: #808080;
}
.line {
  width: 60px;
  height: 0;
  background-color: #092c0b;
  border: solid 1px #092c0b;
}
</style>
