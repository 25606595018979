import { Auth, API } from "aws-amplify"

export default {
  create: async (values) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        sr_code: values.sr_code,
        service_trc_no: values.service_trc_no,
        optional_service_no: values.optional_service_no,
        service_name: values.service_name,
      },
    }
    const reception = await API.post("smariDeliveryboxAPI", `/reception`, init)
    return reception
  },
  createWithPayment: async (values) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        sr_code: values.sr_code,
        service_trc_no: values.service_trc_no,
        optional_service_no: values.optional_service_no,
        service_name: values.service_name,
        amount: values.amount,
      },
    }
    const reception = await API.post("smariDeliveryboxAPI", `/payment-reception`, init)
    return reception
  },
  findByToken: async (transaction_token) => {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()

    const init = {
      headers: {
        Authorization: token,
      },
    }
    const reception = await API.get(
      "smariDeliveryboxAPI",
      `/reception/${transaction_token}`,
      init
    )
    return reception
  },
  getCancelableReception: async () => {
    const session = await Auth.currentSession()
    const token = session.getIdToken().getJwtToken()

    const init = {
      headers: {
        Authorization: token,
      },
    }

    const receptions = await API.get(
      "smariDeliveryboxAPI",
      `/cancelable-reception`,
      init
    )

    return receptions
  },
  update: async (transaction_token, values) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        reception_status: values.reception_status,
        deliverybox_code: values.deliverybox_code,
        deliverybox_key: values.deliverybox_key,
        return_box_no: values.return_box_no,
        service_name: values.service_name,
      },
    }
    const reception = await API.post(
      "smariDeliveryboxAPI",
      `/reception/${transaction_token}`,
      init
    )
    return reception
  },
  createLinkUrl: async (transaction_token) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
    }
    const reception = await API.post("smariDeliveryboxAPI", `/payment-reception/${transaction_token}/link-url`, init)
    return reception
  },
}
