<template>
  <div class="describe">
    <b>
      スマホでお住まいの宅配ボックスから<br />
      カンタンに商品の発送が行えます。
    </b>
  </div>
  <div class="icon-wrapper">
    <img
      class="mobile-icon"
      src="/images/mobile-icon.png"
      alt="スマートフォン"
    />
    <img
      class="deliverybox-icon"
      src="/images/deliverybox-icon.png"
      alt="宅配ボックス"
    />
  </div>
  <div class="actionflow-wrapper">
    <slot></slot>
  </div>
  <ExplainLink />
</template>

<script>
import ExplainLink from "./elements/ExplainLink.vue"

export default {
  name: "ReceptionHeader",
  components: {
    ExplainLink,
  },
}
</script>

<style scoped>
.describe {
  text-align: center;
}
.icon-wrapper {
  width: 150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobile-icon {
  height: 40px;
}
.deliverybox-icon {
  height: 55px;
}
.actionflow-wrapper {
  padding: 10px 0 0;
}
</style>