import { Auth, API } from "aws-amplify"

export default {
  sendBoxReceptionComplete: async (box_no, locker_key) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        box_no: box_no,
        locker_key: locker_key,
      },
    }
    await API.post("smariDeliveryboxAPI", `/email/box-reception-complete`, init)
  },
  sendLockerRegistComplete: async (locker_code, locker_name) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        locker_code: locker_code,
        locker_name: locker_name,
      },
    }
    await API.post("smariDeliveryboxAPI", `/email/locker-regist-complete`, init)
  },
  sendPaymentComplete: async (cancel_url) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
      body: {
        cancel_url: cancel_url,
      },
    }
    await API.post("smariDeliveryboxAPI", `/email/deliverybox-reception`, init)
  },
}
