<template>
  <div class="explain-link">
    <a href="https://smari.io/info-box-smari" target="_blank">宅配ボックス発送の使い方はこちら</a
    >
  </div>
</template>

<script>
export default {
  name: "ExplainLink",
}
</script>

<style scoped>
.explain-link {
  text-align: right;
  margin-bottom: 20px;
}
.explain-link a {
  color: #092c0b;
  text-decoration: underline;
  font-size: 14px;
}
</style>
