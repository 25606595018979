<template>
  <div>
    <loading
      v-model:active="is_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <article class="message is-warning is-small" v-if="error_message">
      <div class="message-body">
        {{ error_message }}
      </div>
    </article>
    <div class="content">
      <ReceptionHeader>
        <PaymentActionFlow
          payment_status="done"
          select_status="active"
          post_status="active"
          complete_status="active"
        />
      </ReceptionHeader>
      <article class="message is-success is-small" v-if="success_message">
        <div class="message-body">
          {{ success_message }}
        </div>
      </article>
      <div v-if="is_allow_action">
        <p class="info">
          発払処理が完了しました。
        </p>
        <div class="btn-wrapper">
          <button
            class="button is-rounded is-medium btn-submit"
            @click="toStoreKeySet"
          >
            次へ
          </button>
        </div>
        <p>発送を取りやめる場合は<a class="area-link is-underlined" @click="toReceptionCancel">こちら</a></p>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay"
import PaymentActionFlow from "../components/elements/PaymentActionFlow.vue"
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import DeliveryboxRepository from "../repositories/DeliveryboxRepository"
import ReceptionHeader from "../components/ReceptionHeader.vue"
import MailService from "../services/MailService"
import ReceptionService from "../services/ReceptionService"

export default {
  name: "PaymentReceptionComplete",
  data() {
    return {
      is_loading: false,
      is_allow_action: false,
      error_message: "",
      success_message: "",
      order_no: "",
      deliverybox: {
        code: "",
        store_no: "",
        store_address: "",
        store_name: "",
        store_locker_name: "",
        store_locker_key: "",
      },
    }
  },
  components: {
    Loading,
    PaymentActionFlow,
    ReceptionHeader,
  },
  computed: {
    store() {
      return this.$store.state.store
    },
  },
  async beforeMount() {
    this.is_loading = true
    if (this.$route.query.token === undefined) {
      this.error_message =
        "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
      return
    }
    try {
      const reception = await DeliveryboxReceptionRepository.findByToken(this.$route.query.token)
      if (!reception) {
        this.error_message = "事前受付が期限切れとなっています。最初から操作を行ってください。\nまたは、既に発送受付処理が完了している商品の可能性があります。ご確認ください。"
        return
      }
      this.$route.query.token = reception.transaction_token
      DeliveryboxRepository.findByCode(reception.deliverybox_code)
        .then((deliverybox) => {
          this.deliverybox.code = reception.deliverybox_code
          this.deliverybox.store_no = deliverybox.store_no
          this.deliverybox.store_address = `${deliverybox.store_prefecture}${deliverybox.store_city}${deliverybox.store_address}${deliverybox.store_address_no}`
          this.deliverybox.store_name = deliverybox.store_name
          this.deliverybox.store_locker_name = deliverybox.store_locker_name
          this.deliverybox.store_locker_key = reception.deliverybox_key
          this.is_show_deliverybox = true
          this.$store.commit("setDeliverybox", this.deliverybox)
        })
      this.is_allow_action = true
      this.order_no = reception.order_no
    } catch (error) {
      this.error_message =
        "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行ってください。"
    } finally {
      this.is_loading = false
    }
  },
  methods: {
    async toStoreKeySet() {
      try {
        const cancel_url = ReceptionService.createCancelUrl(location, this.order_no)
        await MailService.sendPaymentComplete(cancel_url)
      } catch (error) {
        this.error_message = "操作が正常に完了しませんでした。しばらく時間をおいて、最初から操作を行なってください。"
        return
      }
      this.$router.push({
        path: "/payment/store/key-set",
        query: this.$route.query,
      })
    },
    toReceptionCancel() {
      this.$router.push({
        path: `/reception/gmo-payment/${this.order_no}/cancel`,
      })
    },
  },
}
</script>

<style scoped>
.info p {
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}
.btn-wrapper {
  text-align: center;
  padding: 20px 0 50px;
}
.area-link {
  color: #092c0b;
}
</style>
